<template>
  <div>
    <b-select
      size="sm"
      :options="options"
      text-field="designation"
      value-field="id"
      v-model="initialValue"
      @change="update"
    ></b-select>
  </div>
</template>

<script>
export default {
  data() {
    return {
      initialValue: null,
    };
  },
  mounted() {
    this.initialValue = this.value;
  },
  methods: {
    update(value) {
      this.$emit("input", value);
      setTimeout(() => {
        this.initialValue = null;
      }, 200);
    },
  },
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    fieldLabel: {
      type: String,
      default: "",
    },
    fieldValue: {
      type: String,
      default: "",
    },
    value: {
      type: Number,
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped></style>
