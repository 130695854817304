<template>
  <div>
    <b-container class="m-0 p-0">
      <b-row v-for="compound in otherElements" :key="compound.id" class="mb-2">
        <b-col cols="4" class="d-flex align-items-center">
          <h4 class="subtitle-form">{{ compound.name }}</h4>
        </b-col>
        <b-col cols="8" class="d-flex align-items-start">
          <NumericInput
                inputRef="input"
                :value="compound.dose"
                @input="i => {compound.dose = i}"
                @blur="update(compound.id, 'dose', parseFloat($event))"
                :numberOfDigits="1"
                class="mr-2"
            />
          <b-select
            size="sm"
            text-field="unit"
            value-field="id"
            v-model="unit"
            @input="
              update(compound.id, 'unit', $event);
              unit = $event;
            "
            :options="inputFormByPtype.compositionUnits"
          ></b-select>
          <button class="btn align-bottom" @click="() => deleteElement(compound.id)">
            <img :src="delIcon" alt="icon delete action" />
          </button>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <SelectClear :options="compounds" fieldLabel="symbol" fieldValue="id" @input="addElement" />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import delIcon from "@/assets/images/delete.svg";

import SelectClear from "@/components/SelectClear";

import { mapGetters } from "vuex";
import DetailUpdateMixin from "@/mixins/DetailUpdateMixin";
import NumericInput from "@/components/commons/NumericInput.vue";

export default {
  mixins: [DetailUpdateMixin],
  data() {
    return {
      unit: 0,
      delIcon: delIcon,
      otherElements: [],
    };
  },
  async mounted() {
    if (!this.currentEntry.productType) return;

    await Promise.all([this.$store.dispatch("getinputFormByProductType", { id: this.currentEntry.productType })]);

    let toExclude = ["P", "N", "K"];
    this.currentEntry.composition
      .filter(c => !toExclude.includes(c.compound_symbol))
      .forEach(element => {
        this.unit = element.unit;
        this.otherElements.push({
          name: element.compound_symbol,
          id: element.compound,
          dose: element.dose,
          unit: element.unit,
        });
      });
  },
  methods: {
    addElement(value) {
      const element = this.inputFormByPtype.compounds.find(c => c.id == value);
      this.otherElements.push({
        name: element.symbol,
        id: element.id,
      });
    },
    update(id, field, value, deleteElement = false) {
      if (!this.currentEditedEntry.composition || this.currentEditedEntry.composition.length == 0) {
        let cleanData = JSON.parse(JSON.stringify(this.currentEntry.composition || []));
        cleanData = cleanData.map(d => {
          return { compound: d.compound, dose: d.dose, unit: d.unit };
        });
        this.$store.dispatch(this.storeEditAction, {
          composition: cleanData || [],
        });
      }

      let composition = JSON.parse(JSON.stringify(this.currentEditedEntry.composition));

      let data = composition.find(c => c.compound == id) || {};
      data["compound"] = id;

      if (field) {
        data[field] = value;
      }

      if (deleteElement) {
        this.otherElements = this.otherElements.filter(e => e.id != id);
        composition = composition.filter(e => e.compound != id);
      } else {
        composition = [...composition.filter(c => c.compound != data.compound), data];
      }
      this.$store.dispatch(this.storeEditAction, {
        composition: JSON.parse(JSON.stringify(composition)),
      });
    },
    deleteElement(id) {
      this.update(id, null, null, true);
    },
  },
  computed: {
    ...mapGetters(["inputFormByPtype"]),
    compounds() {
      const excluded = ["N", "P", "K"];
      return (this.inputFormByPtype.compounds || []).filter(c => !excluded.includes(c.symbol));
    },
  },
  components: {
    SelectClear,
    NumericInput
  },
};
</script>

<style lang="scss" scoped>
button {
  font-size: 0.8em;
  font-weight: bold;

  img {
    width: 1.5em;
  }
}
</style>
